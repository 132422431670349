import React, { useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import { jwtDecode } from "jwt-decode";

let environment = "development";
if (window.location.href.includes(".test.")) {
  environment = "test";
} else if (window.location.href.includes(".prod.")) {
  environment = "production";
}

try {
  Sentry.init({
    dsn: "https://9ee912bd3de84e72947ba4ba897317d4@o134567.ingest.sentry.io/5716662", // TODO: To be read from SENTRY_DSN variable
    environment,
    normalizeDepth: 6,
    attachStacktrace: true,
    tracesSampleRate: 1.0,
    debug: environment !== "production",
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracePropagationTargets: [/^https:\/\/experience-ext.*\.writing.nike\.com/],
  });

  const jwtToken = localStorage.getItem("token") ?? "";
  if (jwtToken) {
    const decodedToken: LexiJwtPayload = jwtDecode(jwtToken);
    if (decodedToken && decodedToken.sub)
      Sentry.setUser({ email: decodedToken.sub });
  }

  console.log(`Enabled Sentry tracing for ${environment} env!`);
} catch (error) {
  Sentry.captureException(error);
}
