import { NikeI18nProvider } from "@nike/i18n-react";
import {
  NikeDesignSystemProvider,
  Skeleton,
} from "@nike/nike-design-system-components";
import "@nike/nike-design-system-components/polyfills";
import * as Sentry from "@sentry/react";
import React, { Suspense, lazy, useEffect, useState } from "react";
import Helmet from "react-helmet";

import { NOT_AUTHORIZED_MESSAGE, DEFAULT_MESSAGE } from "./shared/constants";
import translations from "../translations.json";
import "./App.css";
import { Rootdiv } from "./App.styled";
import { qualtricsScript } from "./assets/qualtricsScript";
import "./config/sentry";
import supportedLanguages from "./models/locales.json";
import "./config/sentry";
import "./App.css";
import { aegisClient } from "./config/aegis-config";
import { LoginRequired, useAuth } from "@nike/aegis-auth-react";
import { createtHasAccess } from "./shared/authUtils";
import { useGlobalStore } from "./store/store-global";
import { RouterProvider } from "react-router-dom";
const Fallback = lazy(() => import("./pages/Fallback/Fallback"));
import router from "./Routes";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import theme from "./theme/theme";
import { ToastContainer } from "./components/Toast";

function App(): JSX.Element {
  const [uncaughtError, setUncaughtError] = useState("");
  const { loginComplete, setAccessToken, setIdToken } = useAuth(aegisClient);
  const setHasAccess = useGlobalStore((state) => state.setHasAccess);

  useEffect(() => {
    aegisClient.on("renew:access:success", (accessToken) => {
      setAccessToken(accessToken);
      const hasAccess = createtHasAccess(accessToken.claims.groups);
      setHasAccess(hasAccess);
    });
    aegisClient.on("renew:id:success", (idToken) => {
      setIdToken(idToken);
    });
    aegisClient.on("login:expired", (error) => {
      aegisClient.getAccessToken();
      aegisClient.getIdToken();
    });
    aegisClient.on("login:success", ({ accessToken, idToken }) => {
      setAccessToken(accessToken);
      setIdToken(idToken);

      const hasAccess = createtHasAccess(accessToken.claims.groups);
      setHasAccess(hasAccess);
    });

    if (
      window.location.pathname !== "/login" &&
      window.location.pathname !== "/error"
    ) {
      sessionStorage.setItem(
        "from",
        window.location.pathname + window.location.search
      );
    }
  }, [loginComplete]);

  useEffect(() => {
    // Sentry wil not catch error thrown in async code so we have added listner below to catch and show Fallback component
    window.addEventListener("unhandledrejection", (e) => {
      if (!uncaughtError && e.reason.error === "access_denied") {
        setUncaughtError(NOT_AUTHORIZED_MESSAGE);
      } else {
        setUncaughtError(DEFAULT_MESSAGE);
      }
    });
    return () => window.removeEventListener("unhandledrejection", () => {});
  }, []);

  return (
    <Sentry.ErrorBoundary fallback={<Fallback />}>
      {uncaughtError ? (
        <Fallback errorMessage={uncaughtError} />
      ) : !loginComplete ? (
        <Skeleton borderRadius="none" height="100%" width="100%" />
      ) : (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <NikeDesignSystemProvider>
              <Rootdiv data-testid="app" id="modal-root">
                <Helmet>
                  <script type="text/javascript">{qualtricsScript()}</script>
                  <link
                    href="https://www.nike.com/static/ncss/5.0/dotcom/desktop/css/ncss.en-us.min.css"
                    rel="stylesheet"
                  ></link>
                  <link
                    href="https://www.nike.com/static/ncss/nike-design-system-fonts/dotcom/3.0/css/fonts.en-us.css"
                    rel="stylesheet"
                  ></link>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `<div id='ZN_78SGK91iinUDrEy'><!--DO NOT REMOVE-CONTENTS PLACED HERE--></div>`,
                    }}
                  />
                </Helmet>
                <NikeI18nProvider
                  currentLanguageTag="en"
                  supportedLanguages={supportedLanguages}
                  translations={translations}
                >
                  <LoginRequired client={aegisClient}>
                    <Suspense
                      fallback={
                        <Skeleton
                          borderRadius="none"
                          height="100%"
                          width="100%"
                        />
                      }
                    >
                      {" "}
                      <ToastContainer />
                      <RouterProvider router={router} />
                    </Suspense>
                  </LoginRequired>
                </NikeI18nProvider>
              </Rootdiv>
            </NikeDesignSystemProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      )}
    </Sentry.ErrorBoundary>
  );
}

export default Sentry.withProfiler(App, { name: "lexi-v2-frontend" });
