/* eslint-disable react/prop-types */

import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";

import { GET_FEATURE_FLAG_SERVICE } from "../graphql/queries";
import { localizationClient } from "../config/apollo-client";
import CircularProgressContainer from "../components/CircularProgressContainer/CircularProgressContainer";

const model = require("../models/serviceFeatureFlags.json");

export interface FeatureFlagServiceProps {
  featureName: string;
  serviceName: string;
  value: boolean;
  description: string;
}

const FeatureFlagServiceContext = React.createContext(model);

export const FeatureFlagServiceProvider = ({ children }) => {
  const [serviceFeatures, setServiceFeatures] = useState<Map<string, string>>(
    new Map()
  );

  const {
    loading: isMetaDataLoading,
    error: fetchMetaDataError,
    data: metaData,
  } = useQuery(GET_FEATURE_FLAG_SERVICE, {
    client: localizationClient,
    variables: { serviceName: "ui" },
  });

  useEffect(() => {
    if (!isMetaDataLoading && !fetchMetaDataError && metaData) {
      const { fetchFeatureFlag } = metaData;
      if (fetchFeatureFlag) {
        const mappedData: Map<string, string> = new Map(
          fetchFeatureFlag.map((obj: FeatureFlagServiceProps) => [
            obj.featureName,
            obj.value,
          ])
        );
        setServiceFeatures(mappedData);
      }
    }
  }, [isMetaDataLoading]);

  return (
    <FeatureFlagServiceContext.Provider value={{ serviceFeatures }}>
      {isMetaDataLoading ? <CircularProgressContainer /> : children}
    </FeatureFlagServiceContext.Provider>
  );
};

export const useFeatureFlagsService = () =>
  React.useContext(FeatureFlagServiceContext);
